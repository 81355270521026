// REACT
import React from "react"
// MUI
import { Typography } from "@material-ui/core"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"
import { FeaturesList } from "../components/featuresList"
import { SpecificationsTable } from "../components/specificationsTable"

// COMPONENT FUNCTION
const AlcarBoatsClassicPage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      alcarBoatsClassic01: file(
        relativePath: { eq: "alcar-boats-classic-01.jpg" }
      ) {
        ...BsmFluidImage
      }
      alcarBoatsClassic02: file(
        relativePath: { eq: "alcar-boats-classic-02.jpg" }
      ) {
        ...BsmFluidImage
      }
      alcarBoatsClassic03: file(
        relativePath: { eq: "alcar-boats-classic-03.jpg" }
      ) {
        ...BsmFluidImage
      }
    }
  `)

  // FEATURES
  const features = [
    "All stainless steel hardware and fastenings",
    "Level foam floatation",
    "Through-bolted hull and deck",
    "One-inch stainless steel bow rail",
    "Transom splash well",
    "52-gallon rotational molded polyethylene fuel tank",
    "Welded aluminum windshield with tinted safety glass",
    "Large forward storage locker",
    "One-piece watertight self-bailing cockpit",
    "Navigational and courtesy lighting",
  ]

  // SPECIFICATIONS
  const specifications = {
    Length: ["21'"],
    Beam: ['96"'],
    Weight: ["2200 lbs"],
    "Transom Height": ['25"'],
    "Max HP": ["200"],
    "Load Capacity": ["2350 lbs"],
  }

  // COMPONENT
  return (
    <Layout title="Alcar Boats - Classic">
      <PageTitle title="ALCAR CLASSIC" />

      <Typography paragraph>
        Relaxing days aboard the 21’ Alcar Classic are the moments your family
        will treasure. The Classic exceeds the highest standards of safety and
        reliability, and is at home cresting ocean swells or skimming across a
        calm lake. Every aspect of this model is well thought out, beautifully
        crafted, and designed to enhance your boating pleasure.
      </Typography>

      <ImageBlock>
        <ImageWithCaption
          fluid={data.alcarBoatsClassic01.childImageSharp.fluid}
          caption="21' Alcar Classic in the water"
          displayCaption
        />
      </ImageBlock>

      <Typography paragraph style={{ marginBottom: "4rem" }}>
        The Classic makes a convincing argument that you don’t have to spend a
        fortune to get a spacious runabout that combines state-of-the-art
        performance with traditional styling. It’s sure to turn heads at the
        launch ramp. Coupling quality workmanship with functionality, the
        Classic is sure to bring you and your family boating enjoyment for years
        to come.
      </Typography>

      <FeaturesList features={features} />

      <ImageBlock>
        <ImageWithCaption
          fluid={data.alcarBoatsClassic02.childImageSharp.fluid}
          caption="21' Alcar Classic Interior"
          displayCaption
        />
      </ImageBlock>

      <SpecificationsTable specifications={specifications} />

      <ImageBlock>
        <ImageWithCaption
          fluid={data.alcarBoatsClassic03.childImageSharp.fluid}
          caption="21' Alcar Classic underway"
          displayCaption
        />
      </ImageBlock>
    </Layout>
  )
}

export default AlcarBoatsClassicPage
